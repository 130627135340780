<template>
  <card class="indication" max-height="500px" max-width="100%" bg="#191B27">
    <section class="informations">
      <section class="infos">
        <p class="title">{{ $t("views.ambassador.indication.title") + " " + $store.state.user.user.name.replace(/ .*/, "") }}  💪🏻</p>
        <p class="subtitle">
          {{ $t("views.ambassador.indication.subtitle") }}
          <br>
          {{ $t("views.ambassador.indication.subtitle_2") }}
        </p>
      </section>
      <section class="infos">
        <p class="indication_link__title">{{ $t("views.ambassador.indication.link_title") }}</p>
        <p class="indication_link">
          <span>{{ link }}</span>
          <img
            class="icons"
            @click="copyLink"
            alt="copy logo"
            src="@/assets/img/icons/copy_white.svg"
          />
          <img
            class="icons"
            @click="sendToWpp"
            alt="whatsapp logo"
            src="@/assets/img/icons/whatsapp.svg"
          />
          <img
            class="icons"
            @click="sendToFb"
            alt="facebook logo"
            src="@/assets/img/icons/facebook_logo.svg"
          />
          <img
            class="icons"
            @click="sendToTt"
            alt="twitter logo"
            src="@/assets/img/icons/twitter.svg"
          />
        </p>
      </section>
    </section>
    <section class="stars">
      <img
        class="star star-1"
        alt="Star icon"
        src="@/assets/img/icons/solid_star.svg"
        width="30"
        height="30"
      />
      <img
        class="star star-2"
        alt="Star icon"
        src="@/assets/img/icons/solid_star.svg"
        width="22"
        height="22"
      />
      <img
        class="star star-3"
        alt="Star icon"
        src="@/assets/img/icons/solid_star.svg"
        width="38"
        height="38"
      />
    </section>
  </card>
</template>

<script>
// Components
import Card from "../../../../components/shared/Card.vue";
export default {
  components: {
    Card,
  },
  data: () => ({
    link: "https://indico.greenn.me/registro",
  }),
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.link).then(
        () => {
          this.$bvToast.toast(this.$t("views.seller.links.text_1615"), {
            title: this.$t("views.seller.links.text_1616"),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        },
        (err) => {
          this.$bvToast.toast(err, {
            title: "Algo deu errado",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      );
    },
    sendToWpp() {
      window.open(`https://api.whatsapp.com/send?text=${this.link}`, "_blank");
    },
    sendToFb() {
      const link = "https://www.facebook.com/sharer/sharer.php?u=" + this.link;
      window.open(link, "_blank");
    },
    sendToTt() {
      const link = "https://twitter.com/intent/tweet?text=" + this.link;
      window.open(link, "_blank");
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user"))
    this.link += `?confio=${user.id}`
  }
};
</script>

<style lang="scss" scoped>
.indication {
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  @media (min-width: 768px) {
    padding: 40px;
  }

  * {
    color: white;
  }

  .informations {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 35px;
    column-gap: 20px;

    .infos {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .subtitle {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
    }

    .indication_link__title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    .indication_link {
      display: flex;
      column-gap: 25px;
      row-gap: 15px;
      flex-wrap: wrap;
      word-break: break-all;

      span {
        margin-right: 10px;
      }
    }

    .icons {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }

  .stars {
    width: 150px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .star-2 {
      margin-left: 50px;
    }
    .star-3 {
      margin-left: 10px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>
