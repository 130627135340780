<template>
  <card
    class="referred_friends"
    max-height="300px"
    :max-width="maxWidth"
    bg="#191B27"
    bg-position="left-bottom"
    @clicked="$emit('filter')"
  >
    <img
      class="logo"
      alt="link logo"
      src="@/assets/img/icons/link-recruta_white.svg"
      height="15px"
      width="30px"
    />
    <p class="title">{{ $t("views.ambassador.referred_friends.title") }}</p>
    <p class="number">
      <span>{{ total_friends }}</span>
      {{
        total_friends !== 1
          ? $t("views.ambassador.referred_friends.text")
          : $t("views.ambassador.referred_friends.text_secondary")
      }}
    </p>
  </card>
</template>

<script>
// Components
import Card from "../../../../components/shared/Card.vue";
// Services
import AmbassadorService from "@/services/resources/Ambassador";
const service = AmbassadorService.build();

export default {
  components: {
    Card,
  },
  props: {
    maxWidth: {
      type: String,
      required: false,
      default: () => "400px",
    },
  },
  data: () => ({
    total_friends: 0,
  }),
  methods: {
    async getFriends() {
      await service.read("/referred-friends").then((resp) => {
        this.total_friends = resp.referred_friends;
      });
    },
  },
  mounted() {
    this.getFriends();
  },
};
</script>

<style lang="scss" scoped>
.referred_friends {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
  cursor: pointer;

  * {
    color: white;
  }

  .logo {
    rotate: 135deg;
  }

  .title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  .number {
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
    align-items: center;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
    text-align: left;
  }

  .number span {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-self: flex-start;
  }
}
</style>
