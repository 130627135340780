<template>
  <base-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :pagination="pagination"
    @toPage="toPage"
    @perPage="perPage"
  >
  </base-table>
</template>

<script>
// Components
import BaseTable from "@/components/shared/Table.vue";
// Services
import AmbassadorService from "@/services/resources/Ambassador";
const service = AmbassadorService.build();
// Utils
import moment from "moment";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      headers: [
        {
          text: "#" + this.$t("views.ambassador.details.friends_table.id"),
          key: "id",
          cols: 1,
          customClass: "d-none d-md-block",
        },
        {
          text: this.$t("views.ambassador.details.friends_table.accepted_in"),
          key: "accepted_in",
          cols: 4,
          md: 2,
        },
        {
          text: this.$t("views.ambassador.details.friends_table.accepted_per"),
          key: "accepted_per",
          cols: 4,
          md: 2,
        },
        {
          text: this.$t("views.ambassador.details.friends_table.email"),
          key: "email",
          cols: 4,
          md: 3,
          customClass: "text-break",
        },
        {
          text: this.$t("views.ambassador.details.friends_table.cellphone"),
          key: "cellphone",
          customClass: "d-none d-md-block",
        },
      ],
      items: [],
      loading: false,
      pagination: {
        totalPages: 0,
        currentPage: 0,
        perPage: 10,
      },
    };
  },
  methods: {
    perPage(qtd) {
      this.pagination.perPage = qtd;
      this.toPage(1);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.getFriendsList();
    },
    async getFriendsList() {
      this.loading = true;
      const data = {
        url: "list-referred-friends",
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      await service
        .search(data)
        .then((resp) => {
          this.items = resp.data.map((item) => ({
            id: "#" + item.id,
            accepted_in: moment(item.updated_at).format("DD/MM/YYYY hh:mm"),
            accepted_per: item.name,
            email: item.email,
            cellphone: item.cellphone,
          }));
          this.pagination.currentPage = resp.current_page;
          this.pagination.totalPages = resp.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getFriendsList();
  },
};
</script>
