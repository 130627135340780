<template>
  <card
    class="referred_friends"
    max-height="300px"
    :max-width="maxWidth"
    bg="#00AF7B"
    bg-position="right-bottom"
    @clicked="$emit('filter')"
  >
    <img
      class="logo"
      alt="link logo"
      src="@/assets/img/icons/moneyReport_white.svg"
      height="32px"
      width="17px"
    />
    <p class="title">{{ $t("views.ambassador.earnings_referrals.title") }}</p>
    <p class="value">R$ {{ amount }}</p>
  </card>
</template>

<script>
// Components
import Card from "../../../../components/shared/Card.vue";
// Services
import AmbassadorService from "@/services/resources/Ambassador";
const service = AmbassadorService.build();

export default {
  components: {
    Card,
  },
  props: {
    maxWidth: {
      type: String,
      required: false,
      default: () => "400px",
    },
  },
  computed: {
    checkWidth(){
      const real_width = parseInt(this.maxWidth.replace("px", ""))
      return real_width < 350 ? "none" : "right-bottom";
    }
  },
  data: () => ({
    amount: 0,
  }),
  methods: {
    async getAmount() {
      await service.read("/referred-amount").then((resp) => {
        this.amount = resp.referred_amount;
      });
    },
  },
  mounted() {
    this.getAmount();
  },
};
</script>

<style lang="scss" scoped>
.referred_friends {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  cursor: pointer;

  * {
    color: white;
  }

  .title {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .value {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 600;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
  }
}
</style>
